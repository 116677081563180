import {
  GlobalIcon,
  Typography,
  reorderDate
} from "get-life-storybook-ts";
import {
  CapitalStep,
  ContactStep,
  PersonalDataStep
} from "get-life-storybook-ts/lib/components/CalculatorSteps";
import CalculatorPageITController from "./CalculatorPageIT.controller";
import QuotePage from "../QuotePage/QuotePage.component";

const CalculatorPageIT = () => {
  const { contextCalculator, handleClickOrganic, options } =
    CalculatorPageITController();

  return (
    <>
      {contextCalculator.stepCalculator === "1" && (
        <PersonalDataStep
          dateOfBirth={reorderDate(contextCalculator.dateOfBirth)}
          handleClick={contextCalculator.handleClickPersonalData}
          disabledButton={
            contextCalculator.disabledButtons.name ||
            contextCalculator.disabledButtons.dateOfBirth ||
            contextCalculator.disabledButtons.zipCode ||
            !contextCalculator.sex ||
            contextCalculator.disabledNull(contextCalculator.smoke)
          }
          invalidDateMessage={contextCalculator.translate("error.isNull")}
          initialErrorZipCode={contextCalculator.error}
          name={contextCalculator.name}
          postcode={contextCalculator.postcode}
          setDateOfBirth={contextCalculator.setDateOfBirth}
          setName={contextCalculator.setName}
          setPostcode={contextCalculator.setPostcode}
          setSex={contextCalculator.setSex}
          sex={contextCalculator.sex}
          textBtnContinue={contextCalculator.translate("continuar")}
          textDateInput={contextCalculator.translate(
            "calculatorPage.dateLabel"
          )}
          textDay={contextCalculator.translate("calculatorPage.textDay")}
          textMonth={contextCalculator.translate("calculatorPage.textMonth")}
          textNameInput={contextCalculator.translate(
            "calculatorPage.nameLabel"
          )}
          textSex={contextCalculator.translate("calculatorPage.sexLabel")}
          textSexF={contextCalculator.translate("calculatorPage.sexF")}
          textSexM={contextCalculator.translate("calculatorPage.sexM")}
          textYear={contextCalculator.translate("calculatorPage.textYear")}
          textZP={contextCalculator.translate("calculatorPage.zpLabel")}
          validations={contextCalculator.validations}
          smokeOptions={options.smoke}
          setSmoke={contextCalculator.setSmoke}
          textSmoke={contextCalculator.translate(
            "calculatorPage.question.smoke"
          )}
          smokeTooltip={{
            title: contextCalculator.translate("calculatorPage.smoker.tooltip"),
            modalTitle: contextCalculator.translate("calculatorPage.smoker.title"),
            modalBody: (
              <>
                <GlobalIcon iconName="SmokerIcon" size="L" color="var(--primary-color)" />
                <p className="mt-[16px]">
                  {contextCalculator.translate("calculatorPage.smoker.content")}
                </p>
              </>
            ),
          }}
        />
      )}
      {contextCalculator.stepCalculator === "2" && (
        <CapitalStep
          back={contextCalculator.translate("back")}
          backCapital={contextCalculator.translate(
            "calculatorPage.backCapital"
          )}
          disabledButton={contextCalculator.disabledButtons.capital}
          handleClick={contextCalculator.navigateTo}
          handleClickBack={contextCalculator.navigateBack}
          insuranceCover={contextCalculator.insuranceCover}
          maxCapital={contextCalculator.maxCapital}
          minCapital={contextCalculator.minCapital}
          otherCapitalBetween={contextCalculator.translate(
            "calculatorPage.otherCapitalBetween"
          )}
          setInsuranceCover={contextCalculator.setInsuranceCover}
          textAnd={contextCalculator.translate(
            "aggregator.personalInformation.title3"
          )}
          textCapitalQuestion={contextCalculator.translate(
            "calculatorPage.capitalQuestion"
          )}
          textContinue={contextCalculator.translate("continuar")}
          validations={contextCalculator.validations}
          country="it"
          customValues={[
            25000, 50000, 75000, 100000, 150000, 200000, 250000, 300000, 350000,
          ]}
          tooltip={{
            title: "Che cos'è il capitale assicurato",
            modalTitle: (
              <Typography
                text="Che cos'è il capitale assicurato?"
                variant="H3"
                weight="700"
                className="text-dark-gray-100"
              />
            ),
            modalBody: (
              <div className="text-dark-gray-100">
                <GlobalIcon
                  iconName="CalculatorIcon"
                  size="L"
                  color="var(--primary-color)"
                  className="mb-[16px]"
                />
                <Typography
                  text="Il capitale assicurato è l'importo coperto dalla garanzia assicurativa, ovvero l'ammontare che riceverà il tuo/i beneficiario/i in caso di sinistro."
                  variant="Body"
                  weight="400"
                  className="mb-[24px]"
                />
                <div className="flex flex-row gap-[16px]">
                  <GlobalIcon
                    iconName="InformationIcon"
                    size="L"
                    color="var(--primary-color)"
                  />
                  <div className="flex-1">
                    <Typography
                      text="Raccomandazione"
                      variant="H3"
                      weight="700"
                      className="mb-[4px] text-purple"
                    />
                    <Typography
                      text="Gli esperti consigliano di selezionare un capitale assicurato pari a 8-10 volte il reddito annuale"
                      variant="Body"
                      weight="400"
                    />
                  </div>
                </div>
              </div>
            ),
          }}
        />
      )}

      {contextCalculator.stepCalculator === "3" && (
        <ContactStep
          back={contextCalculator.translate("back")}
          btnGetPrice={contextCalculator.translate(
            "calculatorPage.btnGetPrice"
          )}
          checkedConditions={contextCalculator.checkedConditions}
          disabledButton={
            contextCalculator.disabledButtons.phone ||
            contextCalculator.disabledButtons.email ||
            !contextCalculator.checkedConditions ||
            !contextCalculator.checkedConditions2
          }
          email={contextCalculator.email}
          handleClick={handleClickOrganic}
          handleClickBack={contextCalculator.navigateBack}
          initialErrorPhoneNumber={contextCalculator.error}
          phoneNumber={contextCalculator.phoneNumber!}
          setCheckedConditions={contextCalculator.handleCheckConditions}
          setEmail={contextCalculator.setEmail}
          setPhoneNumber={contextCalculator.setPhoneNumber}
          setUrl={contextCalculator.setUrl}
          textAcceptDataProcessing={contextCalculator.translate(
            "calculatorPage.acceptDataProcessing"
          )}
          textEmailQuestion={contextCalculator.translate(
            "calculatorPage.emailQuestion"
          )}
          textPhoneQuestion={contextCalculator.translate(
            "calculatorPage.phoneQuestion"
          )}
          validations={contextCalculator.validations}
          secondCheck={true}
          textAcceptDataProcessing2={contextCalculator.translate(
            "calculatorPage.acceptDataProcessing2"
          )}
          setCheckedConditions2={contextCalculator.handleCheckConditions2}
        />
      )}
      {contextCalculator.stepCalculator === "4" && (
        <QuotePage />
        )}
    </>
  );
};

export default CalculatorPageIT;
